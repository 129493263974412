import type { RuleStatus } from "~/modules/admin/image-moderation/rule/type";
import type { NotificationType } from "~/modules/notifications/types";

export const Routes = {
  landing: {
    home: () => `/`,
    how: () => `/how-it-works`,
    careers: () => `/careers`,
    extension: () => `/extension`,
    communityNotices: () => `/community-notices`,
  },

  auth: {
    login: (options?: { redirect?: string }) => {
      if (!options?.redirect) return `/login`;
      const params = new URLSearchParams();
      params.set("redirect", options?.redirect);
      return `/login?${params.toString()}`;
    },
    logout: () => `/logout`,
    signUp: () => `/sign-up`,
    wisherSignUp: () => `/sign-up?role=wisher`,
    gifterSignUp: () => `/sign-up?role=gifter`,
    confirmationEmail: ({ email, continue: toContinue }: { email: string; continue?: string }) =>
      `/confirmation-email?email=${encodeURIComponent(email)}&continue=${toContinue || ""}`,
    confirmEmailSuccess: () => `/confirm-email/success`,
    forgotPassword: () => `/forgot-password`,
    forgotPasswordSuccess: ({ email }: { email: string }) =>
      `/forgot-password/success?email=${encodeURIComponent(email)}`,
    resetPassword: ({ email, token }: { email: string; token: string }) => {
      return `/reset-password?email=${encodeURIComponent(email)}&token=${token}`;
    },
    resetPasswordSuccess: () => `/reset-password/success`,
  },

  public: {
    wisher: ({ username }: { username: string }) => `/${username}`,
    wish: ({ username, wishId }: { username: string; wishId: string }) =>
      `/${username}/?item=${wishId}`,
    sendSurprise: ({
      username,
      amount,
      note,
    }: {
      username: string;
      amount?: string;
      note?: string;
    }) => {
      const params = new URLSearchParams();
      params.set("surprise", "");
      if (amount) params.set("amount", amount);
      if (note) params.set("note", note);
      return `/${username}/?${params.toString()}`;
    },
    gifter: ({ username }: { username: string }) => `/gifter/${username}`,
    search: {
      general: ({ queryParams }: { queryParams: string }) => `/search?${queryParams}`,
    },
    invite: ({ code }: { code: string }) => `/invite/${code}`,
    inviteGifter: ({ code }: { code: string }) => `/invite/gifter/${code}`,
  },

  leaderboard: {
    oldMain: () => `/leaderboard/old`,
    oldCurrentMonth: () => `/leaderboard/old/current-month`,
    oldDiscordServers: () => `/leaderboard/old/discord`,
    oldDiscordWishers: () => `/leaderboard/old/discord/wishers`,
    main: () => `/leaderboard`,
    discordServers: () => `/leaderboard/discord`,
    discordWishers: () => `/leaderboard/discord/wishers`,
    historicalMain: (timeframe: string) => `/leaderboard/${timeframe}`,
    historicalDiscordServers: (timeframe: string) => `/leaderboard/${timeframe}/discord`,
    historicalDiscordWishers: (timeframe: string) => `/leaderboard/${timeframe}/discord/wishers`,
  },
  cart: {
    default: () => `/cart`,
    subscription: ({ id, token }: { id: string; token?: string }) => {
      if (!token) return `/cart/${id}`;
      const searchString = new URLSearchParams();
      searchString.set("token", token);
      return `/cart/${id}?${searchString.toString()}`;
    },
    request: ({ request }: { request: string }) => `/cart/request/${request}`,
  },

  settings: {
    main: () => `/account-settings`,
  },
  discord: {
    landing: () => `/discord`,
    invite: () => `/discord/invite`,
  },

  notifications: {
    main: ({
      cursor,
      type,
      excludeType,
    }: {
      cursor?: string;
      type?: NotificationType;
      excludeType?: NotificationType;
    }) => {
      if (!cursor && !type && !excludeType) return "/notifications";
      const searchString = new URLSearchParams();
      if (cursor) searchString.set("cursor", cursor);
      if (type) searchString.set("type", type);
      if (excludeType) searchString.set("excludeType", excludeType);
      return `/notifications?${searchString.toString()}`;
    },
  },

  gifter: {
    onboard: () => `/gifter/onboard`,
    orderList: (searchParams?: URLSearchParams) => {
      if (!searchParams) {
        return "/gifter/gifts/orders";
      }
      return `/gifter/gifts/orders?${searchParams.toString()}`;
    },
    subscriptionList: () => "/gifter/gifts/subscriptions",
    cartRequestList: () => "/gifter/gifts/requests",
    blockSendingCartRequestByEmailSuccess: () => "/block-sending-request/success",
    declineCartRequestByEmailSuccess: () => "/decline-cart-request/success",
    settings: {
      main: () => "/gifter/settings",
    },
    badges: {
      main: () => "/gifter/badges",
    },
  },

  wisher: {
    activeWishlist: () => "/internal/activate8265",
    wishlistSetup: () => `/wishlist-setup`,
    onboard: () => `/wisher/onboard`,
    orderList: (searchParams?: URLSearchParams) => {
      if (!searchParams) {
        return "/wisher/wishes/orders";
      }

      return `/wisher/wishes/orders?${searchParams.toString()}`;
    },
    orderDetails: ({ id }: { id: string }) => `/wisher/wishes/${id}`,
    subscriptionList: () => `/wisher/wishes/subscriptions`,
    cartRequestList: () => `/wisher/wishes/requests`,
    settings: {
      main: () => "/wisher/settings",
      sendNewTestGift: () => "/wisher/settings/new/test/gift/send",
      sendSeenTestGift: () => "/wisher/settings/seen/test/gift/send",
    },
    deleteCartRequestFromEmailSuccess: () => "/delete-cart-request/success",
    setupPayment: () => "/stripe_notice",
    connectSuccess: () => "/connect-success",
    communityNotice: ({ username }: { username: string }) => `/${username}/community-notice`,
    profile: {
      edit: ({ username }: { username: string }) => `/${username}/?profile=edit`,
    },
    balance: {
      main: () => "/wisher/balance",
      account: () => "/wisher/balance/account",
      history: () => "/wisher/balance/history",
    },
    referrals: {
      main: () => "/wisher/referrals",
    },
    paymentDashboard: {
      main: () => "/payments/overview",
      payoutList: () => "/payments/payouts",
      activityList: (activity?: string) => {
        const query = activity ? `?activity=${activity}` : "";
        return "/payments/activities" + query;
      },
      accountVerify: () => "/verify",
      payoutMethod: () => "/payments/payouts/method",
      transactionHistory: () => "/payments/activities",
      updates: () => "/payments/updates",
      support: () => "/payments/support",
    },
    payoutSetup: {
      main: () => "/payments/payouts/setup",
      success: () => "/payments/payouts/setup/success",
    },
    identityVerification: {
      main: (sessionId?: string) => {
        const query = sessionId ? `?session=${sessionId}` : "";
        return `/identity/verify${query}`;
      },
      success: () => "/identity/verify/success",
    },
  },

  admin: {
    main: () => "/admin",
    restrictedSearchKeywords: () => "/admin/restricted-search-keywords",
    complianceManagement: {
      itemSearch: (searchParams?: URLSearchParams) => {
        if (searchParams) {
          return `/admin/compliance-management/item-search?${searchParams.toString()}`;
        }
        return `/admin/compliance-management/item-search`;
      },
      queries: () => "/admin/compliance-management/queries",
      reports: () => "/admin/compliance-management/reports",
      reportDetail: (reportId: string) => `/admin/compliance-management/reports/${reportId}`,
    },
    usersSearch: () => "/admin/users",
    userDetail: ({ userId }: { userId: string }) => `/admin/users/${userId}`,
    registry: () => "/admin/registry",
    eventLogs: () => "/admin/event-logs",
    referrals: () => "/admin/referrals",
    feed: () => "/admin/feed",
    statementDescriptors: () => "/admin/statement-descriptors",
    supportedCountriesFees: () => "/admin/supported-countries-fees",
    beta: {
      screening: () => "/admin/beta",
      screeningReport: ({ reportId }: { reportId: string }) => `/admin/beta/reports/${reportId}`,
    },
    userDetailV2: {
      main: ({ userId }: { userId: string }) => `/admin/users/v2/${userId}`,
      registry: {
        gift: {
          list: ({ userId }: { userId: string }) => `/admin/users/v2/${userId}/registry/gifts`,
          detail: ({ orderId }: { orderId: string }) => `/admin/orders/${orderId}`,
        },
        subscription: {
          list: ({ userId }: { userId: string }) =>
            `/admin/users/v2/${userId}/registry/subscriptions`,
        },
      },
      payments: {
        main: ({ userId }: { userId: string }) => `/admin/users/v2/${userId}/payments`,
        transactions: ({ userId }: { userId: string }) =>
          `/admin/users/v2/${userId}/payments/transactions`,
      },
    },
    checkout: {
      detail: ({ checkoutId, scrollTo }: { checkoutId: string; scrollTo?: "refundHistory" }) => {
        const searchParams = new URLSearchParams();
        if (scrollTo) {
          searchParams.set("scrollTo", scrollTo);
        }

        return `/admin/checkouts/${checkoutId}?${searchParams.toString()}`;
      },
    },
    transactions: {
      list: () => "/admin/transactions",
      detail: ({ transactionId }: { transactionId: string }) =>
        `/admin/transactions/${transactionId}`,
    },
    moderation: {
      media: {
        users: () => "/admin/moderation/media/users",
        reviewQueue: () => "/admin/moderation/media/review",
        reviewLog: (searchParams?: URLSearchParams) => {
          return `/admin/moderation/media/review/log?${searchParams?.toString()}`;
        },
        rules: ({ status }: { status?: RuleStatus }) => {
          const searchParams = new URLSearchParams();
          if (status) {
            searchParams.set("status", status);
          }

          return `/admin/moderation/media/rules?${searchParams.toString()}`;
        },
      },
    },
  },

  user: {
    payment: {
      payin: {
        methods: () => "/payments/payin/methods",
      },
    },
  },

  redirect: () => "/redirect",

  proxy: {
    dynamicImage: (path: string) => `/proxy/dynamic-image?path=${encodeURIComponent(path)}`,
  },

  loaders: {
    notification: {
      newNotificationsCount: ({
        type,
        excludeType,
      }: {
        type?: NotificationType;
        excludeType?: NotificationType;
      }) => {
        if (!type && !excludeType) return "loaders/notifications/new/count";
        const searchString = new URLSearchParams();
        if (type) searchString.set("type", type);
        if (excludeType) searchString.set("excludeType", excludeType);
        return `loaders/notifications/new/count?${searchString.toString()}`;
      },
    },
    search: {
      gifters: ({ query }: { query: string }) => `/loaders/search/gifters?queryString=${query}`,
    },
    wisher: {
      requests: {
        templates: () => `/loaders/wisher/requests/templates`,
      },
      wishes: {
        unopenedOrders: () => `/loaders/orders/unopened/count`,
      },
    },
    admin: {
      compliance: {
        eventLogs: ({
          page,
          limit,
          wisherId,
        }: {
          page: number;
          limit: number;
          wisherId: string;
        }) => {
          const params = new URLSearchParams({
            page: page.toString(),
            limit: limit.toString(),
            wisherId,
          });
          return `/loaders/admin/compliance/event-logs/?${params.toString()}`;
        },
      },
      wishlist: (wisherId: string) => `/loaders/admin/wishlist/?wisherId=${wisherId}`,
      users: {
        stripeAccounts: (userId: string) => `/loaders/admin/users/stripe-accounts?userId=${userId}`,
        wisherOrders: (params: {
          wisherId: string;
          searchText?: string;
          page: number;
          limit: number;
        }) => {
          const queryString = new URLSearchParams({
            wisherId: params.wisherId,
            searchText: params.searchText ?? "",
            page: params.page.toString(),
            limit: params.limit.toString(),
          }).toString();

          return `/loaders/admin/wishers/orders?${queryString}`;
        },
        wisherDisputes: (wisherId: string) =>
          `/loaders/admin/wishers/orders/disputes/count?wisherId=${wisherId}`,
        wisherSubscriptions: (wishlistId: string) =>
          `/loaders/admin/wishers/subscriptions?wishlistId=${wishlistId}`,
        gifterOrders: (params: {
          gifterId: string;
          searchText?: string;
          page: number;
          limit: number;
        }) => {
          const queryString = new URLSearchParams({
            gifterId: params.gifterId,
            searchText: params.searchText ?? "",
            page: params.page.toString(),
            limit: params.limit.toString(),
          }).toString();
          return `/loaders/admin/gifters/orders?${queryString}`;
        },
        gifterDisputes: (gifterId: string) =>
          `/loaders/admin/gifters/orders/disputes/count?gifterId=${gifterId}`,
        gifterSubscriptions: (gifterId: string) =>
          `/loaders/admin/gifters/subscriptions?gifterId=${gifterId}`,
        referralCodes: (userId: string) => `/loaders/admin/users/referrals/codes?userId=${userId}`,
        referrals: (params: { userId: string; page: number; limit: number }) =>
          `/loaders/admin/users/referrals?userId=${params.userId}&page=${params.page}&limit=${params.limit}`,
        referralCommissions: (params: { userId: string; page: number; limit: number }) =>
          `/loaders/admin/users/referrals/commissions?userId=${params.userId}&page=${params.page}&limit=${params.limit}`,
        decisions: ({ userId }: { userId: string }) => `/loaders/admin/users/${userId}/decisions`,
      },
      orders: {
        stripe: (orderId: string) => `/loaders/admin/orders/stripe?orderId=${orderId}`,
        eventLogs: ({ orderId }: { orderId: string }) =>
          `/loaders/admin/orders/${orderId}/event-logs`,
      },
    },
  },

  actions: {
    user: {
      update: () => "/actions/user/update",
      resetPassword: () => "/actions/user/reset/password",
    },
    auth: {
      resendConfirmation: () => `/actions/auth/confirmation/resend`,
    },
    cart: {
      checkout: () => `/actions/cart/checkout`,
      checkoutDetails: ({
        checkoutId,
        transactionId,
      }: {
        checkoutId: string;
        transactionId: string;
      }) => `/actions/cart/checkout/details?checkout=${checkoutId}&transaction=${transactionId}`,
      pay: () => `/actions/cart/checkout/pay`,
      checkoutSubscription: ({ token }: { token?: string }) =>
        `/actions/cart/checkout/subscription` + (token ? `?token=${token}` : ""),
      checkoutRequest: ({ token }: { token?: string }) =>
        `/actions/cart/checkout/request` + (token ? `?token=${token}` : ""),
      updateItem: () => `/actions/cart/item/update`,
      addItem: () => `/actions/cart/item/add`,
      addTipItem: () => `/actions/cart/item/tip/add`,
    },
    wisher: {
      identityVerification: {
        recordSession: () => "/actions/identity/record-session",
      },
      profile: {
        setup: () => `/actions/wisher/profile/setup`,
        update: () => `/actions/wisher/profile/update`,
        updateAvatar: () => `/actions/wisher/profile/avatar/update`,
        updateCover: () => `/actions/wisher/profile/cover/update`,
        updateSocialLinks: () => `/actions/wisher/profile/social-links/update`,
        connectStripe: () => `/actions/wisher/profile/connect-stripe`,
      },
      order: {
        open: () => `/actions/wisher/order/open`,
        thank: () => `/actions/wisher/order/thank`,
      },
      wishlist: {
        activate: () => `/actions/wisher/wishlist/activate`,
        update: () => `/actions/wisher/wishlist/update`,
        item: {
          add: () => `/actions/wisher/wishlist/item/add`,
          update: () => `/actions/wisher/wishlist/item/update`,
          delete: () => `/actions/wisher/wishlist/item/delete`,
          addWorker: () => `/actions/wisher/wishlist/item/worker/add`,
          infoWorker: () => `/actions/wisher/wishlist/item/worker/info`,
        },
        productInfo: () => `/actions/wisher/wishlist/product-info`,
        category: {
          update: () => `/actions/wisher/wishlist/category/update`,
          delete: () => `/actions/wisher/wishlist/category/delete`,
        },
      },
      cart: {
        request: {
          create: () => `/actions/wisher/cart/requests/create`,
          cancel: () => `/actions/wisher/cart/requests/cancel`,
          share: () => `/actions/wisher/cart/requests/share`,
          remove: () => `/actions/wisher/cart/requests/remove`,
        },
      },
      connectAccount: {
        activateConnect: () => "/actions/connect-account/activate-connect",
      },
      balance: {
        payout: () => "/actions/wisher/balance/payout",
      },
      referral: {
        createCode: () => "/actions/wisher/referral/code/create",
      },
      payment: {
        payout: () => "/actions/wisher/payment/payout",
        finishOnboarding: () => `/actions/wisher/payment/onboarding/finish`,
      },
    },
    gifter: {
      profile: {
        setup: () => `/actions/gifter/profile/setup`,
        update: () => `/actions/gifter/profile/update`,
      },
      subscription: {
        cancel: ({ id }: { id: string }) => `/actions/gifter/subscription/cancel`,
      },
      delete: () => "/actions/gifter/delete",
      cart: {
        request: {
          decline: () => "/actions/gifter/cart/requests/decline",
          block: () => "/actions/gifter/cart/requests/block",
          remove: () => "/actions/gifter/cart/requests/remove",
        },
      },
      statementDescriptor: {
        update: () => "/actions/gifter/statement-descriptor/update",
      },
      settings: {
        update: () => "/actions/settings/gifter/update",
      },
    },
    validateUsername: ({ username }: { username: string }) =>
      `/actions/username/validate?username=${username}`,
    notifications: {
      seen: () => `/actions/notifications/seen`,
      markSeen: () => `/actions/notifications/mark-seen`,
      markSeenAll: () => `/actions/notifications/seen-all`,
    },
    discord: {
      checkout: () => `/actions/discord/checkout`,
    },
    request: {
      checkout: () => `/actions/request/checkout`,
      archiveTemplate: () => "/actions/wisher/cart/request/template/archive",
    },
    settings: {
      updateEmail: () => "/actions/settings/email/update",
      deleteWisher: () => "/actions/settings/wisher/delete",
      resetPassword: () => "/actions/settings/password/reset",
      updateDisplayCurrency: () => "/actions/settings/display/currency/update",
      updateSurpriseGift: () => "/actions/settings/surprise/gift/update",
      updateUserSettings: () => "/actions/settings/user/update",
      generateStreamUrl: () => "/actions/settings/stream/url/generate",
      linkTwitter: () => "/actions/settings/twitter/link",
      unlinkTwitter: () => "/actions/settings/twitter/unlink",
      enableAutoTweet: () => "/actions/settings/twitter/auto/enable",
      disableAutoTweet: () => "/actions/settings/twitter/auto/disable",
      updateTwitterSettings: () => "/actions/settings/twitter/update",
    },
    notices: {
      markSeen: () => "/actions/notices/mark-seen",
    },
    admin: {
      search: {
        createRestrictedSearchKeyword: () => "/actions/admin/create/restricted-search-keywords",
        deleteRestrictedSearchKeyword: () => "/actions/admin/delete/restricted-search-keywords",
      },
      wishlist: {
        createComplianceReport: () => "/actions/admin/compliance-report/create",
        updateComplianceQuery: () => "/actions/admin/compliance-query/update",
        sendActionIncompliantItems: () => `/actions/admin/incompliant-items/send-action`,
      },
      users: {
        delete: () => "/actions/admin/users/delete",
        updateCountry: () => "/actions/admin/users/country/update",
        updateWisherCurrency: () => "/actions/admin/wishers/currency/update",
        updateGifterCurrency: () => "/actions/admin/gifters/currency/update",
        updateAdminNote: () => "/actions/admin/users/admin-note/update",
        toggleWisherFreeze: () => "/actions/admin/wishers/freeze/toggle",
        deleteWisherCover: () => "/actions/admin/wishers/cover/delete",
        deleteWisherAvatar: () => "/actions/admin/wishers/avatar/delete",
        detachWisherStripe: () => "/actions/admin/wishers/stripe/detach",
        updateEmail: () => "/actions/admin/users/email/update",
        updateFeatureFlags: () => "/actions/admin/users/feature-flags/update",
        togglePayoutFreeze: () => "/actions/admin/wishers/payout/freeze/toggle",
        getBalance: (userId: string) => `/actions/admin/users/${userId}/balance`,
      },
      stripe: {
        delete: () => "/actions/admin/stripe/delete",
        updateInstantPayout: () => "/actions/admin/stripe/instant-payout/update",
      },
      referralCodes: {
        update: () => "/actions/admin/referrals/codes/update",
        create: () => "/actions/admin/referrals/codes/create",
      },
      statementDescriptors: {
        create: () => "/actions/admin/statement-descriptors/create",
        update: (id: string) => `/actions/admin/statement-descriptors/update/${id}`,
      },
      payment: {
        detachPayoutMethod: () => `/actions/admin/payments/payouts/methods/detach`,
        syncPayoutMethod: () => `/actions/admin/payments/payouts/methods/sync`,
        linkPayoutMethod: () => `/actions/admin/payments/payouts/methods/link`,
      },
      refunds: {
        create: () => "/actions/admin/refunds/create",
      },
      topup: {
        create: () => "/actions/admin/topup/create",
      },
      beta: {
        searchScreeningWishers: ({ userIds }: { userIds: string[] }) => {
          const searchParams = new URLSearchParams();
          for (const userId of userIds) {
            searchParams.append("userIds", userId);
          }
          return `/actions/admin/beta/search-screening-wishers?${searchParams.toString()}`;
        },
        decide: () => `/actions/admin/beta/decide`,
        decision: {
          undo: (decisionId: string) => `/actions/admin/beta/decisions/${decisionId}/undo`,
        },
      },
      mediaModeration: {
        rule: {
          create: () => `/actions/admin/mediamoderation/rules/create`,
          update: (id: string) => `/actions/admin/mediamoderation/rules/${id}/update`,
        },
        review: {
          decide: () => `/actions/admin/mediamoderation/review/decide`,
        },
      },
    },
    payment: {
      method: {
        delete: (id: string) => `/actions/payments/methods/delete/${id}`,
        create: () => "/actions/payments/methods/create",
      },
      payout: {
        method: {
          add: () => `/actions/payments/payouts/methods/add`,
        },
      },
      guidedTutorial: {
        remainingReminder: {
          update: () => "/actions/payments/guided-tutorials/remaining-reminders/update",
        },
      },
    },
  },
  external: {
    intercom: () => `https://intercom.help/wishtender`,
    intercomProhibitedItems: () =>
      `https://intercom.help/wishtender/en/articles/5497375-what-items-are-prohibited-on-my-wishlist`,
    privacyPolicy: () => `/files/privacy.pdf`,
    termsOfService: () => `/files/WishTender_Terms_of_Service.pdf`,
    gifterSurvey: () => `https://forms.gle/ViEAzSYfBw8m98mX6`,
  },
};

/**
 * Can be use with useRouteLoaderData()
 */
export const RouteIds = {
  admin: {
    search: {
      restrictedSearchKeywords: () => "routes/admin.restricted-search-keywords",
    },
    complianceManagement: {
      reportDetail: () => "routes/admin.compliance-management.reports_.$reportId",
      itemSearch: () => "routes/admin.compliance-management.item-search",
    },
    users: {
      list: () => "routes/admin.users",
      detail: () => "routes/admin.users_.$userId",
    },
    eventLogs: {
      list: () => "routes/admin.event-logs",
    },
    userDetailV2: {
      registry: {
        gift: {
          list: () => "routes/admin.users_.v2.$userId.registry.gifts",
        },
      },
    },
    order: {
      detail: () => "routes/admin.orders.$orderId",
    },
  },
  wisher: {
    layout: () => `routes/wisher`,
    order: {
      list: () => "routes/wisher.wishes.orders",
    },
    subscription: {
      list: () => "routes/wisher.wishes.subscriptions",
    },
    request: {
      list: () => "routes/wisher.wishes.requests",
    },
    settings: () => "routes/wisher.settings",
    balance: () => "routes/wisher.balance",
    referrals: {
      layout: () => "routes/wisher_.referrals",
      list: () => "routes/wisher_.referrals._index",
    },
  },
  gifter: {
    layout: () => `routes/gifter`,
    order: {
      list: () => "routes/gifter.gifts.orders",
    },
    subscription: {
      list: () => "routes/gifter.gifts.subscriptions",
    },
    request: {
      list: () => "routes/gifter.gifts.requests",
    },
    settings: () => "routes/gifter.settings",
  },
  search: {
    layout: () => `routes/search`,
  },
  public: {
    wishlist: () => "routes/$handle",
    gifter: () => "routes/gifter_.$username",
    cancelledSubscription: () => "routes/subscriptions.$subscription.cancelled",
    subscriptionInfo: () => "routes/subscriptions.$subscription.info",
  },
  notifications: {
    list: () => "routes/notifications",
  },
  loaders: {
    notifications: {
      newCount: () => "routes/loaders.notifications.new.count",
    },
    wisher: {
      wishes: {
        unopenedOrders: () => `routes/loaders.orders.unopened.count`,
      },
    },
    admin: {
      orders: {
        eventLogs: () => "routes/loaders.admin.orders.$orderId.event-logs",
      },
      users: {
        decisions: {
          list: () => "routes/loaders.admin.users.$userId.decisions",
        },
      },
    },
    payment: {
      activities: {
        csv: () => "routes/loaders.payment.activities.csv",
      },
    },
  },
  cart: {
    layout: () => "routes/cart",
    request: () => "routes/cart_.request.$request",
  },
  leaderboard: {
    layout: () => "routes/leaderboard",
  },
  login: {
    layout: () => "routes/login",
  },
};
